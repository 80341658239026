
import i18n from "../assets/json/i12n";
import actionsType from "./actionsType";
import initialMe from "../assets/json/i12n/me/fr.json"
import initielMyCv from "../assets/json/i12n/myCv/fr.json"

const me = (state = initialMe, action) => {
    switch (action.type) {
        case actionsType.changeLang:
            return i18n.me[action.playload.fr]
        default:
            return state;

    }
}

const myCv = (state = initielMyCv, action) => {
    switch (action.type) {
        case actionsType.changeLang:
            return i18n.myCv[action.playload.fr]
        default:
            return state;


    }
}



const reduicers = { me, myCv }

export default reduicers