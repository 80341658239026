import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    Routes,
} from "react-router-dom";

import fr from "../assets/json/i12n/myCv/fr.json"
import  Home from "../dinyad/Home";
const loader = ({params})=>{
    return [ fr.projects[params.id-1],[fr.projects[(params.id)%fr.projects.length],(params.id)%fr.projects.length+1],[fr.projects[(params.id+1)%fr.projects.length],(params.id+1)%fr.projects.length+1] ]
  }
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path='/' element={<Home />} />
        </Route>
    )
);

export default router

// 959x874