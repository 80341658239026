import React ,{ Component }from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor, FiPhone, FiServer, FiTablet, FiRotateCcw, FiRotateCw, FiCornerUpRight, FiLock, FiDatabase, FiSidebar } from "react-icons/fi";

// const ServiceList = [
//     {
//         icon: <FiCast />,
//         title: 'Business Stratagy',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiLayers />,
//         title: 'Website Development',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'Marketing & Reporting',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     { 
//         icon: <FiMonitor />,
//         title: 'Mobile App Development',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'Marketing & Reporting',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     { 
//         icon: <FiMonitor />,
//         title: 'Mobile App Development',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     }
// ]


const ServiceList = [
    {
        icon: <FiSidebar />,
        title: 'Développement Web',
        description: 'Pour rester visible, votre entreprise a besoin d’un site. Vitrine, portail, e-commerce, blog, forum, institutionnel, communautaire…'
    },
    {
        icon: <FiTablet />,
        title: 'Développement Mobile',
        description: 'Vous l’auriez compris, avec les applications mobiles, tout est mieux. Nous vous proposons un travail sur mesure.'
    },
    {
        icon: <FiMonitor />,
        title: 'Développement Desktop',
        description: 'Voulez-vous une version bureautique pour votre application ? Windows, Linux ou Mac ? On gère tout pour vous .'
    },
    {
        icon: <FiDatabase />,
        title: 'Intelligence Artificielle',
        description: 'Progresser votre maturité en IA pour booster votre croissance. Comprendre vos données, prendre des décisions à travers des modèles d’IA.'
    },
    {
        icon: <FiLock />,
        title: 'Sécurité informatique',
        description: 'Vous n’allez quand même pas laisser vous équipements exposés ! De l’installation jusqu’au monitoring on est là !'
    },
    {
        icon: <FiCornerUpRight />,
        title: 'Réorientation',
        description: 'Vous souhaitez devenir programmeur, peut importe votre parcours précédent, nous sommes là pour vous aider. '
    },
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a>
                                <div className="service service__style--2 text-center bg-dark">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
