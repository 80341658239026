import logo from './logo.svg';
import './App.css';
import "./index.scss"
import { Provider } from 'react-redux';
import store from './redux/store';

import {RouterProvider} from 'react-router-dom';
import router from './router/root';

function App() {
  return (
    <Provider store={store}>
      <RouterProvider router={router}/>
    </Provider>
  );
}

export default App;
